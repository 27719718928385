@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

a {
  -webkit-touch-callout: none;
}
.v-application--wrap {
  padding-top: var(--safe-area-inset-top);
  padding-bottom: var(--safe-area-inset-bottom);
}
.v-toolbar {
  padding-top: var(--safe-area-inset-top);
  height: calc(var(--safe-area-inset-top) + 64px) !important;
}
.v-bottom-navigation {
  padding-bottom: calc(var(--safe-area-inset-bottom) + 56px);
}
.v-bottom-sheet {
  padding-bottom: var(--safe-area-inset-bottom) !important;
}
.v-footer {
  padding-bottom: var(--safe-area-inset-bottom) !important;
}
.theme--light .v-expansion-panels .v-expansion-panel {
  background-color: map-get($grey, "lighten-4") !important;
}
.theme--dark .v-expansion-panels .v-expansion-panel {
  background-color: #121212 !important;
}
