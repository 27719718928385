@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.progress-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
}
.v-carousel {
  position: fixed;
  bottom: var(--safe-area-inset-bottom);
}
