@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.avatar-icon {
  background-color: var(--avatar-color);
  border-radius: 50%;
  color: map-get($shades, "white");
  height: var(--avatar-size);
  outline: solid 2px map-get($shades, "white");
  width: var(--avatar-size);
}
.avatar-image {
  border-radius: 50%;
  height: var(--avatar-size);
  outline: solid 2px map-get($shades, "white");
  width: var(--avatar-size);
}
.avatar-initials {
  align-items: center;
  background-color: var(--avatar-color);
  border-radius: 50%;
  color: map-get($shades, "white");
  display: flex;
  font-size: calc(var(--avatar-size) / 4);
  font-weight: 500;
  height: var(--avatar-size);
  justify-content: center;
  outline: solid 2px map-get($shades, "white");
  text-align: center;
  width: var(--avatar-size);
}
.adminBadge {
  background-color: map-get($shades, "white");
  border-radius: 50%;
  top: -5px;
  color: var(--v-secondary-base);
  padding: 2px;
  position: absolute;
  left: -5px;
}
.badge {
  background-color: var(--v-primary-base);
  border-radius: 50%;
  bottom: -5px;
  color: map-get($shades, "white");
  outline: solid 2px map-get($shades, "white");
  padding: 2px;
  position: absolute;
  right: -5px;
}
