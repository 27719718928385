@import "~vuetify/src/styles/settings/_colors.scss";

$body-font-family: "Open Sans", sans-serif !default;
$heading-font-family: "Archivo Black", sans-serif !default;

$grid-breakpoints: (
  "xs": 0,
  "sm": 600px,
  "md": 960px,
  "lg": 960px,
  "xl": 960px,
);

$material-light: (
  "app-bar": map-get($shades, "white"),
  "background": map-get($grey, "lighten-4"),
);
