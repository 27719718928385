@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-toolbar__extension {
  background-color: var(--v-primary-base);
}
::v-deep .v-badge__badge .v-icon {
  color: var(--v-primary-base);
}
::v-deep .v-input__slot {
  padding: 0px !important;
}
.v-list-item__icon {
  align-self: center;
}
