@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-label {
  font-size: 0.8rem;
  position: absolute !important;
  top: 0px;
}
.cropper-container {
  height: 300px;
  width: 300px;
  position: relative;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
