@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.background {
  background: var(--v-primary-base);
  height: 100%;
}
.carousel-container {
  height: 150px;
  width: 100%;
}
.logo-container {
  text-align: center;
  width: 100%;
}
.underline-text {
  text-decoration: underline;
  text-underline-offset: 3px;
}
