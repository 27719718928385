@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.is-mobile {
  .card-right {
    font-size: 0.9rem;

    .place-name {
      font-size: 1.15rem;
    }
  }
}

.card-right {
  position: relative;

  .place-name {
    font-family: $heading-font-family;
    font-size: 1.25rem;
  }

  .travel-time-duration {
    bottom: 15px;
    left: 10px;
    position: absolute;

    .icon {
      background-color: var(--v-secondary-base);
      border-radius: 10px 0 0 10px;
      border: 1px solid var(--v-secondary-base);
      padding: 0.1em 0.4em 0.2em 0.5em;
    }
    .duration {
      border-radius: 0 10px 10px 0;
      border: 1px solid var(--v-secondary-base);
      padding: 0.1em 0.7em 0.2em 0.2em;
    }
  }

  .vote-btn {
    bottom: 10px;
    position: absolute;
    right: 10px;
  }
}
.placeIndex {
  align-items: center;
  background-color: map-get($shades, "white");
  border-radius: 50%;
  border: 2px solid var(--v-secondary-base);
  color: var(--v-secondary-base);
  display: flex;
  font-size: 18px;
  font-weight: bold;
  height: 30px;
  justify-content: center;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 30px;
}

.truncate-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate-two-lines {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  line-clamp: 2;
  overflow: hidden;
}
