@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.message {
  align-items: flex-end;
  color: white;
  display: flex;
  justify-content: space-between;

  ::v-deep a {
    color: white !important;
  }

  .time {
    font-size: 0.8em;
    margin-left: 8px;
    opacity: 0.7;
  }
}
.v-footer {
  .container {
    position: relative;

    .scroll-btn {
      position: absolute;
      bottom: 100px;
      right: 0px;
    }
  }
}
