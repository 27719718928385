@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.mapbox-map {
  height: 100%;
}
.mapboxgl-map {
  height: 100%;
}
.map-overlay {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
.hide-controls .mapboxgl-ctrl-bottom-left,
.hide-controls .mapboxgl-ctrl-bottom-right {
  display: none;
}
